import NavigationMenu from "./NavigationMenu"
import { useState } from 'react'
import FormationsTypeWriter from './FormationsTypeWriter'
import SEO from './SEO'
import Couteau from '../images/formations/couteau.png'
import Plasti from '../images/Logos/plasti.png'
import Enviro from '../images/Logos/enviro.png'
import Camaq from '../images/Logos/camaq.png'
import Horti from '../images/Logos/horti.png'
import Metallurgie from '../images/Logos/metallurgie.png'
import Loto from '../images/Logos/loto.png'
import Desjardins from '../images/Logos/desjardins.png'
import Bixi from '../images/Logos/bixi.png'
import Barreau from '../images/Logos/barreau.png'
import Bondouelle from '../images/Logos/bonduelle.png'
import Hema from '../images/Logos/hema.png'

export default function Formations() {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            <SEO title='Le couteau suisse de vos formations' description='Avec notre couteau suisse : aiguisez les compétences de vos apprenants en créant pour eux des parcours pédagogique personnalisés !' language='FR' link='https://algego.com/formations' />
            <div>
                <NavigationMenu showMenu={showMenu} setShowMenu={setShowMenu} />
            </div>

            <div className="textTypeRealisation">
                <FormationsTypeWriter />
            </div>

            <div className="container-fluid">
                <div className="formationsSubTitleRed">
                    Prêt à entrer dans la nouvelle dimension de la formation multimédia ?
                </div>
                <div className="formationsSubTitle">
                    <p>Avec notre couteau suisse :</p>
                    <p>aiguisez les compétences de vos apprenants</p>
                    <p>en créant pour eux des parcours pédagogique personnalisés !</p>
                </div>
                <div className="forIframeSize">
                    <iframe className="temoignageVideo" src="https://player.vimeo.com/video/1015358143?h=c51a73d634" width="800" height="450" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="afterCouteau">
                    <p>Analyse de besoins | Conception pédagogique</p>
                    <p>Entrevues d’experts | Production vidéo</p>
                    <p>Modélisation 3D | Motion capture</p>
                    <p>Réalisaté augmentée | Ludification</p>
                    <p>Intégration sur Articulate Storyline - StoryLine 360</p>
                    <p>Diffusion sur vos plateformes d’apprentissage (LMS)</p>
                </div>
            </div>
            <div className="sectionCouteau">
                <img src={Couteau}></img>
            </div>
            <div className="formationBanner">
                Clients | CSMO
            </div>
            <div className="formationLogo1">
                <div>
                    <img src={Plasti}></img>
                    <img src={Enviro}></img>
                    <img src={Camaq}></img>
                </div>
                <div>
                    <img src={Horti}></img>
                    <img src={Metallurgie}></img>
                </div>
            </div>

            <div className="formationBanner">
                Clients | organismes et entreprises
            </div>
            <div className="formationLogo2">
                <div>
                    <img src={Loto}></img>
                    <img src={Desjardins}></img>
                    <img src={Bixi}></img>
                </div>
                <div className="formationLogo2_2ndRow">
                    <img src={Barreau}></img>
                    <img src={Bondouelle}></img>
                    <img src={Hema}></img>
                </div>
            </div>
        </>
    )
}